import { useEffect, useState } from 'react';
import Safe from '@safe-global/protocol-kit';
import {
  getCreateCallDeployment,
  getDefaultCallbackHandlerDeployment,
  getFallbackHandlerDeployment,
  getMultiSendCallOnlyDeployment,
  getMultiSendDeployment,
  getProxyFactoryDeployment,
  getSafeSingletonDeployment,
  getSignMessageLibDeployment,
  getSimulateTxAccessorDeployment,
} from '@safe-global/safe-deployments';
import { useNetwork } from 'wagmi';

import { useSignerAddress } from '@/hooks/useEthersSigner';

export const useSafeSdk = (safeAddress: string, chainId?: number) => {
  const { chain } = useNetwork();
  const signer = useSignerAddress({ chainId });
  const [sdk, setSdk] = useState<Safe>();

  useEffect(() => {
    return () => setSdk(undefined);
  }, []);

  useEffect(() => {
    const currentChainId: string | undefined = chain?.id?.toString();
    const safeSingletonDeployment = getSafeSingletonDeployment({
      network: currentChainId,
    });
    const proxyFactoryDeployment = getProxyFactoryDeployment({
      network: currentChainId,
    });
    const multiSendDeployment = getMultiSendDeployment({
      network: currentChainId,
    });
    const multiSendCallOnlyDeployment = getMultiSendCallOnlyDeployment({
      network: currentChainId,
    });
    const defaultFallbackHandlerDeployment =
      getDefaultCallbackHandlerDeployment({
        network: currentChainId,
      });
    const fallbackHandlerDeployment = getFallbackHandlerDeployment({
      network: currentChainId,
    });
    const signMessageLibDeployment = getSignMessageLibDeployment({
      network: currentChainId,
    });
    const createCallDeployment = getCreateCallDeployment({
      network: currentChainId,
    });
    const simulateTxAccessorDeployment = getSimulateTxAccessorDeployment({
      network: currentChainId,
    });
    const fallbackObj =
      defaultFallbackHandlerDeployment || fallbackHandlerDeployment;
    const load = async () => {
      if (!chain?.id) return;
      const contractNetworks: any = {
        '169': {
          safeProxyFactoryAddress: '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
          safeMasterCopyAddress: '0x3E5c63644E683549055b9Be8653de26E0B4CD36E',
          multiSendAddress: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
          multiSendCallOnlyAddress:
            '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
          fallbackHandlerAddress: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
          signMessageLibAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
          createCallAddress: '0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4',
        },
        '34443': {
          safeProxyFactoryAddress: '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
          safeMasterCopyAddress: '0x3E5c63644E683549055b9Be8653de26E0B4CD36E',
          multiSendAddress: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
          multiSendCallOnlyAddress:
            '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
          fallbackHandlerAddress: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
          signMessageLibAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
          createCallAddress: '0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4',
        },
      };
      if (currentChainId) {
        contractNetworks[currentChainId] = {
          safeMasterCopyAddress:
            safeSingletonDeployment?.networkAddresses[currentChainId],
          safeMasterCopyVersion: safeSingletonDeployment?.version,
          safeProxyFactoryAddress:
            proxyFactoryDeployment?.networkAddresses[currentChainId],
          multiSendAddress:
            multiSendDeployment?.networkAddresses[currentChainId],
          multiSendCallOnlyAddress:
            multiSendCallOnlyDeployment?.networkAddresses[currentChainId],
          fallbackHandlerAddress: fallbackObj?.networkAddresses[currentChainId],
          signMessageLibAddress:
            signMessageLibDeployment?.networkAddresses[currentChainId],
          createCallAddress:
            createCallDeployment?.networkAddresses[currentChainId],
          simulateTxAccessorAddress:
            simulateTxAccessorDeployment?.networkAddresses[currentChainId],
        };
      }
      const eip1193 = window.ethereum;
      try {
        const protocolKit = await Safe.init({
          provider: eip1193 as any,
          signer: signer,
          safeAddress,
          contractNetworks,
        });
        setSdk(protocolKit);
      } catch (error: any) {
        console.error('Error in Safe.create():', error);
      }
    };
    load();
  }, [signer, safeAddress, chain?.id]);
  return sdk;
};
